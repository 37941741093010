module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-126982744-1"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"evrenkass","defaultLang":"en-us","pages":[{"type":"Note","match":"/notes/:uid","path":"/notes","component":"/opt/build/repo/src/templates/note.tsx"},{"type":"Work","match":"/works/:uid","path":"/works","component":"/opt/build/repo/src/templates/work.tsx"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
