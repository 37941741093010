export const linkResolver = doc => {
  if (doc._meta.type === 'language') {
    return `/work-language/${doc.code}`;
  }

  if (doc._meta.type === 'note') {
    return `/notes/${doc._meta.uid}`;
  }

  if (doc._meta.type === 'work') {
    return `/works/${doc._meta.uid}`;
  }
  return `/`;
};
